<template>
  <div class="bg-gray-200 dark:bg-gray-800 bg-opacity-50 py-20">
    <div class="wrapper">
      <template v-if="loading">
        <RoseSpinner />
      </template>

      <template v-else>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 justify-center items-center">
          <div v-for="(report, index) in reports"
               :key="index"
               class="flex flex-col justify-center items-center gap-2
             text-gray-700 dark:text-white bg-gray-300 dark:bg-gray-700 transition-all hover:-mt-2 hover:border-b-4 border-b-2 border-gray-500 rounded-lg py-6">
            <div class="text-2xl">
              <span class="font-bold">{{ report.value }}</span>
              <span> +</span>
            </div>

            <div class="text-sm">{{ report.title }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InfoService from "@/services/InfoService";

export default {
  name: "Reports",
  data() {
    return {
      loading: false,
      reports: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;

      InfoService.getReports()
        .then(({ data }) => {
          this.reports = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
