<template>
  <button class="w-8 h-4 rounded-full bg-white flex items-center transition duration-300 focus:outline-none shadow"
          @click="toggleTheme()">
    <div id="switch-toggle"
         :class="[
           'w-6 h-6 relative rounded-full transition duration-500 transform p-1 text-white',
           isDarkMode
             ? 'bg-gray-700 -translate-x-3/4'
             : 'bg-yellow-500 translate-x-2',
         ]">
      <img :src="themeIcon"
           alt="theme icon"
           height="16"
           width="16" />
    </div>
  </button>
</template>

<script>
import { getItem, setItem } from "@/core/helpers/localStorageHelper";

const THEME_KEY = "gb_theme";
const DARK_THEME = "dark";
const LIGHT_THEME = "light";

const darkIcon = require("@/assets/images/moon.svg");
const lightIcon = require("@/assets/images/sun.svg");

export default {
  name: "DarkModeSwitch",
  data() {
    return {
      theme: LIGHT_THEME,
    };
  },
  created() {
    this.initTheme();
  },
  methods: {
    getSavedTheme() {
      return getItem(THEME_KEY);
    },
    saveTheme(theme) {
      setItem(THEME_KEY, theme);
    },
    initTheme() {
      const savedTheme = this.getSavedTheme();

      if (savedTheme && savedTheme.length)
        this.theme = savedTheme;

      this.changeTheme(this.theme);
    },
    toggleTheme() {
      if (this.theme === LIGHT_THEME) {
        this.changeTheme(DARK_THEME);
      } else {
        this.changeTheme(LIGHT_THEME);
      }
    },
    changeTheme(theme) {
      const bodyElement = document.body;

      this.theme = theme;
      this.saveTheme(theme);

      if (theme === LIGHT_THEME) {
        bodyElement.classList.remove("dark");
      } else {
        bodyElement.classList.add("dark");
      }
    },
  },
  computed: {
    isDarkMode() {
      return this.theme === DARK_THEME;
    },
    themeIcon() {
      return this.isDarkMode ? darkIcon : lightIcon;
    },
  },
};
</script>

<style></style>
