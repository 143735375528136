<template>
  <button :class="['btn', buttonClass]"
          :disabled="loading">

    <i v-if="icon"
       v-show="!loading"
       :class="icon"
       class="align-middle text-sm me-3"></i>

    <RoseSpinner v-show="loading"
             class="me-3 !h-6 !w-6"
             light />

    <slot />

  </button>
</template>

<script>
import RoseSpinner from "@/components/utilities/RoseSpinner";

export default {
  name: "BaseButton",
  components: { RoseSpinner },
  props: {
    variant: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      return "btn-" + this.variant;
    }
  }
};
</script>
