<template>
  <div>
    <label v-if="label"
           :for="id"
           class="block text-sm dark:text-gray-300 mb-2">{{ label }}</label>
    <div :class="['text-gray-900 dark:text-gray-300',
           'bg-white dark:bg-gray-600',
           'border border-gray-400 dark:border-gray-700 rounded-lg',
           'p-2',
           { 'shadow-lg': focused },
           { 'border-transparent ring-2 ring-gray-400 dark:ring-gray-700': focused && !error.length },
           { '!border-red-400': error.length },
         ]"
         class="flex items-center w-full text-sm shadow-md">
      <slot name="prepend" />

      <input :id="id"
             :value="modelValue"
             class="w-full bg-transparent outline-none p-4"
             v-bind="$attrs"
             @blur="focused = false"
             @focus="focused = true"
             @input="updateValue" />

      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: [String, Number],
    id: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    updateValue(event) {
      this.$emit("update:modelValue", event.target.value);
    },
    hasSlot(name) {
      return this.$slots[name] !== undefined;
    },
  },
};
</script>
