<template>
  <div class="my-3 text-center items-center align-center">
    <div class="flex justify-center items-center">
      <div class="loader animate-spin rounded-full border-4 border-t-4 border-gray-400 h-8 w-8"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoseSpinner",
};
</script>

<style scoped>
.loader {
  border-top-color: transparent;
}
</style>