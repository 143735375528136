<template>
  <footer class="pb-20">
    <div class="wrapper relative">
      <div
        class="relative z-10 text-sm bg-white dark:bg-gray-800 dark:text-gray-100 rounded-4xl shadow-xl space-y-6 p-8 md:px-16"
      >
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div class="space-y-4">
            <div class="flex gap-6">
              <router-link :to="{ name: 'Home' }">
                <img
                  alt="گرندبیتکس"
                  class="w-20"
                  src="@/assets/images/logo/grandbitex-logo-70x43.png"
                />
              </router-link>

              <div>
                <div class="text-lg font-bold text-black dark:text-white">
                  گـرنـدبـیـتـکس
                </div>
                <div class="text-2xs text-gray-600 dark:text-gray-300">
                  بازار حرفه‌ای رمزارزها
                </div>
              </div>
            </div>

            <p class="leading-relaxed">
              گرندبیتکس بستری امن جهت خرید، فروش و معامله انواع ارزهای دیجیتال
              است. اگر به دنبال خرید ارز دیجیتال ارزان یا فروش آنلاین رمز ارز در
              ایران هستید، گرندبیتکس فضایی مناسب جهت معامله آنلاین ارزهای
              دیجیتال در ایران است.
            </p>
          </div>
          <div>
            <h4 class="text-lg font-bold mb-4">لینک‌های کاربردی</h4>

            <ul class="list-none space-y-4">
              <li>
                <router-link
                  :to="{ name: 'About' }"
                  class="block hover:text-green-400"
                >
                  درباره ما
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'Policy' }"
                  class="block hover:text-green-400"
                >
                  قوانین
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'Guide' }"
                  class="block hover:text-green-400"
                >
                  راهنمای استفاده
                </router-link>
              </li>

              <li>
                <router-link
                  :to="{ name: 'FAQ' }"
                  class="block hover:text-green-400"
                >
                  سوالات متداول
                </router-link>
              </li>

              <li>
                <a
                  class="block hover:text-green-400"
                  href="https://mag.grandbitex.net"
                  rel="noopener"
                  target="_blank"
                >
                  مجله
                </a>
              </li>

              <li>
                <router-link
                  :to="{ name: 'UserLevels' }"
                  class="block hover:text-green-400"
                >
                  سطوح کاربری
                </router-link>
              </li>
            </ul>
          </div>

          <div>
            <h4 class="text-lg font-bold mb-4">ارتباط</h4>

            <address v-if="contactInfo" class="not-italic">
              <div class="space-y-3">
                <div class="grid grid-cols-12 items-center space-s-2">
                  <div class="col-span-1">
                    <LocationMarkerIcon class="w-7 h-7 text-green-400" />
                    <div class="sr-only">Location</div>
                  </div>

                  <div class="col-span-11">
                    <p class="mb-0">{{ contactInfo.address }}</p>
                  </div>
                </div>

                <div class="grid grid-cols-12 items-center space-s-2">
                  <div class="col-span-1">
                    <MailIcon class="w-7 h-7 text-green-400" />
                    <div class="sr-only">Email</div>
                  </div>

                  <div class="col-span-11">
                    <a :href="['mailto:' + contactInfo.email]" itemprop="email">
                      {{ contactInfo.email }}
                    </a>
                  </div>
                </div>

                <div class="grid grid-cols-12 items-center space-s-2">
                  <div class="col-span-1">
                    <PhoneIcon class="w-7 h-7 text-green-400" />
                    <div class="sr-only">Phone</div>
                  </div>

                  <div class="col-span-11">
                    <a :href="['tel:' + callNumber]">
                      {{ contactInfo.phone }}
                    </a>
                  </div>
                </div>

                <div class="grid grid-cols-12 items-center space-s-2">
                  <div class="col-span-1 text-center">
                    <i class="fa fa-headset text-2xl text-green-400"></i>
                    <div class="sr-only">Support</div>
                  </div>

                  <div class="col-span-11">8 الی 17 روزهای غیر تعطیل</div>
                </div>

                <div class="grid grid-cols-12 items-center space-s-2">
                  <div class="col-span-1">
                    <ChatAlt2Icon class="w-7 h-7 text-green-400" />
                    <div class="sr-only">Ticket</div>
                  </div>

                  <div class="col-span-11">تمامی روزها به صورت 24 ساعته</div>
                </div>
              </div>
            </address>
          </div>
          <div class="text-center w-full">
            <h4 class="text-lg font-bold lg:mb-24 mb-4"></h4>
            <a
              referrerpolicy="origin"
              target="_blank"
              href="https://trustseal.enamad.ir/?id=301710&Code=eNArDrdhtpeh5I8FMyU4"
              ><img
                class="h-[8.5rem]"
                referrerpolicy="origin"
                src="https://trustseal.enamad.ir/Content/Images/Star2/30.png?v=5.0.0.47&id=301710&Code=eNArDrdhtpeh5I8FMyU4"
                alt=""
                style="cursor: pointer"
                id="eNArDrdhtpeh5I8FMyU4"
            /></a>
          </div>
        </div>

        <div class="flex justify-center items-center gap-2 mt-6">
          <div class="group social-link-wrapper">
            <a
              :href="contactInfo.twitter"
              class="social-link"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fab fa-twitter"></i>
              <span class="sr-only">Twitter link</span>
            </a>
          </div>

          <div class="group social-link-wrapper">
            <a
              :href="contactInfo.telegram"
              class="social-link"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fab fa-telegram"></i>
              <span class="sr-only">Telegram link</span>
            </a>
          </div>

          <div class="group social-link-wrapper">
            <a
              :href="contactInfo.linkedin"
              class="social-link"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fab fa-linkedin"></i>
              <span class="sr-only">Linkedin link</span>
            </a>
          </div>

          <div class="group social-link-wrapper">
            <a
              :href="contactInfo.instagram"
              class="social-link"
              rel="noreferrer"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
              <span class="sr-only">Instagram link</span>
            </a>
          </div>
        </div>

        <div class="flex justify-center items-center text-center align-center">
          <div class="text-sm">
            <span class="me-1">&copy;</span>

            <span
              >کلیه حقوق مادی و معنوی این سایت متعلق به گرندبیتکس می‌باشد.</span
            >
          </div>
        </div>
      </div>

      <div
        class="hidden lg:block absolute z-2 h-full w-11/12 top-6 start-12 bg-gray-200 dark:bg-gray-700 rounded-4xl"
      ></div>

      <div
        class="hidden lg:block absolute z-1 h-full w-10/12 top-12 start-24 bg-gray-200 bg-opacity-40 dark:bg-gray-600 rounded-4xl"
      ></div>
    </div>
  </footer>
</template>

<script>
import {
  ChatAlt2Icon,
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/vue/outline";
// import InfoService from "@/services/InfoService";

export default {
  components: {
    LocationMarkerIcon,
    MailIcon,
    PhoneIcon,
    ChatAlt2Icon,
  },
  data() {
    return {
      urlImage: "@/assets/images/pages/home/enamad.jpg",
      contactInfo: {
        address: "اصفهان، دروازه تهران",
        email: "info@grandbitex.net",
        phone: "03133371007",
        twitter: "https://twitter.com/grand_bitex",
        telegram: "",
        linkedin: "",
        instagram: "https://www.instagram.com/grandbitex",
      },
    };
  },
  created() {
    // this.getContactInfo();
  },
  methods: {
    // getContactInfo() {
    //   InfoService.getAllInfo()
    //     .then(({ data }) => {
    //       this.contactInfo = data.baseDTO;
    //     });
    // },
  },
  computed: {
    callNumber() {
      return "+98" + this.contactInfo.phone.substring(1);
    },
  },
};
</script>

<style scoped>
a.router-link-exact-active {
  @apply text-green-400 pointer-events-none;
}

.social-link-wrapper {
  @apply border-2 border-gray-200 rounded-full p-1;
}

.social-link {
  @apply block text-lg text-gray-600 bg-gray-200 rounded-full transition-colors group-hover:bg-green-400 group-hover:text-white pt-2 pb-1 px-3;
}
</style>
