import CoinService from "@/services/CoinService";
import CoinIcon from "@/components/coins/CoinIcon.vue";

const baseBuyLink = "https://app.grandbitex.net/dashboard/buy_";
const baseSellLink = "https://app.grandbitex.net/dashboard/sell_";

export default {
  props: {
    query: {
      type: String,
      required: true,
    },
  },
  components: {
    CoinIcon,
  },
  data() {
    return {
      loading: false,
      tetherBuyPrice: 0,
      tetherSellPrice: 0,
      coinsList: [],
    };
  },
  created() {
    this.loadCoins();
  },
  computed: {
    filteredCoinsList() {
      return this.coinsList.filter((coin) => {
        const search = this.query.toLowerCase();

        return (
          coin.name.toLowerCase().includes(search) ||
          coin.slug.toLowerCase().includes(search) ||
          coin.key.toLowerCase().includes(search)
        );
      });
    },
  },
  methods: {
    loadCoins() {
      this.loading = true;

      CoinService.getCoins()
        .then(({ data }) => {
          this.coinsList = data;

          const tether = this.coinsList.find(
            (coin) => coin.slug.toLowerCase() === "usdt"
          );
          this.tetherBuyPrice = tether.buy;
          this.tetherSellPrice = tether.sell;
        })
        .catch()
        .finally(() => {
          this.loading = false;
        });
    },
    getBuyPrice(coin) {
      return coin.usdt * this.tetherBuyPrice;
    },
    getSellPrice(coin) {
      return coin.usdt * this.tetherSellPrice;
    },
    buyLink(coin) {
      return baseBuyLink + coin.key;
    },
    sellLink(coin) {
      return baseSellLink + coin.key;
    },
    isDisabled(coin) {
      return coin.disabled;
    },
  },
};
