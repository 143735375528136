<template>
  <img v-lazy="src"
       :alt="symbol"
       class="h-auto w-8"
       height="32"
       width="32" />
</template>

<script>
export default {
  name: "CoinIcon",
  props: {
    src: {
      type: String,
      required: true,
    },
    symbol: {
      type: String,
      required: true,
    },
  },
};
</script>
