<template>
  <div class="space-y-1">
    <h3 class="text-gray-700 text-2xl font-black dark:text-gray-200">{{ title }}</h3>

    <h6 class="text-sm dark:text-gray-400">{{ subtitle }}</h6>
  </div>
</template>

<script>
export default {
  name: "SectionTitle",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
  },
};
</script>
