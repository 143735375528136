import { nextTick } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "خانه" },
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("@/views/About.vue"),
    meta: { title: "درباره ما" },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("@/views/Policy.vue"),
    meta: { title: "قوانین" },
  },
  {
    path: "/guide",
    name: "Guide",
    component: () => import("@/views/Guide.vue"),
    meta: { title: "راهنمای استفاده" },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import("@/views/FAQ.vue"),
    meta: { title: "سوالات متداول" },
  },
  {
    path: "/user-levels",
    name: "UserLevels",
    component: () => import("@/views/UserLevels.vue"),
    meta: { title: "سطوح کاربری" },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/errors/Error404.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  let result = { behavior: "smooth" };

  savedPosition ? (result.savedPosition = savedPosition) : (result.top = 0);

  return result;
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
});

const DEFAULT_TITLE = "گرندبیتکس";
router.beforeEach((to) => {
  nextTick(() => {
    document.title =
      to.meta && to.meta.title
        ? to.meta.title + " - " + DEFAULT_TITLE
        : DEFAULT_TITLE;
  });
});

export default router;
