<template>
  <header
    :class="[scrolled ? 'h-16' : 'h-24']"
    class="fixed top-0 right-0 left-0 z-50 transition-all"
  >
    <div
      class="flex items-center relative h-full border-b dark:border-gray-600 bg-gray-50 dark:bg-gray-900 shadow-sm py-3 px-2 lg:px-10"
    >
      <nav class="wrapper flex flex-grow justify-between items-center gap-2">
        <div class="flex flex-grow items-center gap-6">
          <h1>
            <router-link :to="{ name: 'Home' }" class="flex items-center gap-4">
              <img
                alt="گرندبیتکس | صرافی آنلاین ارز دیجیتال"
                class="w-16 max-w-full h-auto"
                src="@/assets/images/logo/grandbitex-logo-70x43.png"
              />

              <div>
                <div class="text-lg font-bold text-black dark:text-white">
                  گـرنـدبـیـتـکس
                </div>
                <div class="text-2xs text-gray-600 dark:text-gray-300">
                  بازار حرفه‌ای رمزارزها
                </div>
              </div>
            </router-link>
          </h1>

          <div
            :class="{
              'hidden md:flex': !isOpen,
              'absolute right-0 left-0 top-full border-gray-500 border-t bg-gray-50 dark:bg-gray-900 shadow-2xl':
                isOpen,
            }"
            class="flex flex-grow flex-col-reverse md:flex-row md:items-center justify-between px-2 pb-2 md:pb-0"
          >
            <ul
              class="list-none flex flex-col md:flex-row text-sm lg:text-base dark:text-gray-100"
            >
              <li>
                <router-link :to="{ name: 'Home' }" class="nav-link">
                  خانه
                </router-link>
              </li>

              <li>
                <a
                  class="nav-link"
                  href="https://mag.grandbitex.net"
                  target="_blank"
                >
                  مجله
                </a>
              </li>

              <li>
                <router-link :to="{ name: 'Guide' }" class="nav-link">
                  راهنما
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'Policy' }" class="nav-link">
                  قوانین
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'FAQ' }" class="nav-link">
                  سؤالات متداول
                </router-link>
              </li>

              <li>
                <router-link :to="{ name: 'About' }" class="nav-link">
                  درباره ما
                </router-link>
              </li>
            </ul>

            <a
              class="group text-sm dark:text-gray-100 hover:text-gray-50 hover:bg-green-400 dark:hover:bg-gray-700 rounded-xl transition hover:shadow-lg py-3 px-5 my-2 md:my-0"
              href="https://app.grandbitex.net/login"
              target="_blank"
            >
              <div class="flex md:justify-center items-center gap-3">
                <UsersIcon
                  class="text-gray-600 group-hover:text-gray-100 transition-colors h-6 w-6"
                />

                <span class="font-medium">ورود | ثبت‌نام</span>
              </div>
            </a>
          </div>
        </div>

        <div class="flex items-center gap-2">
          <DarkModeSwitch class="mx-2" />

          <button
            aria-label="Menu"
            class="md:hidden dark:text-gray-100"
            @click="isOpen = !isOpen"
          >
            <template v-if="!isOpen">
              <MenuIcon class="text-gray-400 dark:text-gray-100 w-9 h-9 p-1" />

              <span class="sr-only">Open menu</span>
            </template>

            <template v-if="isOpen">
              <XIcon class="text-gray-400 dark:text-gray-100 w-9 h-9 p-1" />

              <span class="sr-only">Close menu</span>
            </template>
          </button>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import { MenuIcon, UsersIcon, XIcon } from "@heroicons/vue/outline";
import router from "@/router";
import DarkModeSwitch from "@/components/utilities/DarkModeSwitch.vue";

export default {
  name: "Header",
  components: {
    MenuIcon,
    XIcon,
    UsersIcon,
    DarkModeSwitch,
  },
  data() {
    return {
      isOpen: false,
      scrolled: false,
    };
  },
  created() {
    this.handleHeightOnScroll();
    this.closeMenu();
  },
  computed: {
    name() {
      return this.data;
    },
  },
  methods: {
    handleHeightOnScroll() {
      const offset = 50;

      const doc = document.documentElement;

      window.addEventListener("scroll", () => {
        const top =
          (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

        this.scrolled = top > offset;
      });
    },
    closeMenu() {
      router.beforeEach(() => {
        this.isOpen = false;
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  @apply block md:inline hover:bg-gray-200 dark:hover:bg-gray-700 rounded transition-colors px-3 py-2;
}

a.router-link-exact-active {
  @apply text-green-400;
}
</style>
