<template>
  <div class="space-y-8">
    <div class="flex gap-6">
      <TwoColorDivider />

      <SectionTitle subtitle="همیشه با ما بروز خواهید بود"
                    title="با ما بروز باشید" />
    </div>

    <RoseSpinner v-if="posts.length === 0"></RoseSpinner>

    <swiper :autoplay="swiperOptions.autoplay"
            :breakpoints="swiperOptions.breakpoints"
            :loop="swiperOptions.loop"
            :modules="swiperOptions.modules"
            :pagination="swiperOptions.pagination"
            :slides-per-view="1"
            :space-between="10"
            class="custom-swiper"
            @slideChange="onSlideChange"
            @swiper="onSwiper">
      <swiper-slide v-for="post in posts"
                    :key="post.id"
                    class="!h-72">
        <a :href="post.link"
           :style="{ backgroundImage: `url('${getImage(post)}')` }"
           class="h-full block bg-cover bg-center bg-no-repeat rounded-lg"
           rel="noopener"
           target="_blank">
          <div class="h-full flex flex-col justify-between rounded-lg bg-gradient-to-tl from-black/80 to-transparent hover:from-black py-4 px-6">
            <div>
              <a v-show="getCategory(post)"
                 :href="getCategory(post).link"
                 class="max-w-max text-white text-xs bg-gray-300 bg-opacity-60 rounded-4xl hover:underline py-1.5 px-3"
                 rel="noopener"
                 target="_blank">
                {{ getCategory(post).name }}
              </a>
            </div>

            <div class="space-y-3">
              <div class="space-y-1">
                <p class="text-gray-300 text-xs">
                  <span>منتشر شده در</span>
                  <span>{{ " " + getPublishDate(post.date) }}</span>
                </p>

                <h4 class="text-white text-xl font-bold text-shadow">
                  {{ post.title.rendered }}
                </h4>
              </div>

              <div class="h-0.5 w-3/5 bg-gray-300 bg-opacity-50 rounded-lg"></div>

              <div class="flex gap-4 text-gray-300 text-xs">
                <div class="flex items-center gap-1">
                  <ClockIcon class="w-5 h-5" />

                  <div>
                    <span>{{ getReadingTime(post) }}</span>
                    <span>{{ " " }}</span>
                    <span>دقیقه</span>
                  </div>
                </div>

                <div class="flex items-center gap-1">
                  <EyeIcon class="w-5 h-5" />

                  <div>
                    <span>{{ getViews(post) }}</span>
                    <span>{{ " " }}</span>
                    <span>بازدید</span>
                  </div>
                </div>

                <div class="flex items-center gap-1">
                  <ChatIcon class="w-5 h-5" />

                  <div>
                    <span>{{ getCommentsCount(post) }}</span>
                    <span>{{ " " }}</span>
                    <span>نظر</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { ChatIcon, ClockIcon, EyeIcon } from "@heroicons/vue/outline";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import BlogService from "@/services/BlogService";
// import { CalendarIcon } from "@heroicons/vue/outline";
import RoseSpinner from "@/components/utilities/RoseSpinner.vue";
import SectionTitle from "@/components/utilities/titles/SectionTitle";
import TwoColorDivider from "@/components/utilities/dividers/TwoColorDivider";

export default {
  components: {
    TwoColorDivider,
    SectionTitle,
    // CalendarIcon,
    Swiper,
    SwiperSlide,
    ClockIcon,
    EyeIcon,
    ChatIcon,
    RoseSpinner,
  },
  data() {
    return {
      posts: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
        modules: [Autoplay, Pagination],
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        pagination: {
          clickable: true,
        },
        loop: true,
      },
    };
  },
  created() {
    BlogService.getLatestPosts(10)
      .then((response) => {
        this.posts = response.data;
      });
  },
  methods: {
    filterPosts() {
      this.posts = this.posts.slice(0, 3);
    },
    getImage(post) {
      if (!post) return;

      const img = post["_embedded"]?.["wp:featuredmedia"]?.[0]?.["media_details"]?.["sizes"]?.["medium_large"]?.["source_url"];

      return img ? img : "";
    },
    getPublishDate(date) {
      return new Date(date).toLocaleDateString("fa-IR");
    },
    getCategory(post) {
      if (!post.metadata?.category)
        return false;

      const category = post.metadata.category;

      return {
        name: category.name,
        link: category.link,
      };
    },
    getReadingTime(post) {
      const readingTime = post.metadata?.reading_time;

      return readingTime ? readingTime : "0";
    },
    getViews(post) {
      const views = post.metadata?.views_count;

      return views ? views : "0";
    },
    getCommentsCount(post) {
      const commentsCount = post.metadata?.comments_count;

      return commentsCount ? commentsCount : "0";
    },
    onSwiper() {
      // console.log("onSwiper");
    },
    onSlideChange() {
      // console.log("onSlideChange");
    },
  },
};
</script>

<style>
.text-shadow {
  text-shadow: 1px 1px 3px #000;
}
</style>
