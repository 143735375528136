<template>
  <div class="grid grid-cols-1 lg:grid-cols-2">
    <div
      :style="{ backgroundImage: `url('${require('@/assets/images/pages/home/pattern.png')}')` }"
      class="flex items-center bg-contain bg-center bg-no-repeat m-6 md:m-10"
    >
      <div class="w-full space-y-10">
        <div class="space-y-2">
          <h2 class="text-2xl md:text-4xl font-black text-black dark:text-white">گرندبیتکس</h2>

          <p class="text-lg md:text-xl dark:text-gray-400">امن‌ترین سامانه خرید و فروش ارزهای دیجیتال</p>
        </div>

        <!--<base-input id="hero-search-input"-->
        <!--            v-model="search"-->
        <!--            placeholder="نام ارز مورد نظر خود را وارد کنید...">-->
        <!--  <template #append>-->
        <!--    <base-button class="min-w-max btn-md rounded-md"-->
        <!--                 variant="primary">جستجو کنید-->
        <!--    </base-button>-->
        <!--  </template>-->
        <!--</base-input>-->
      </div>
    </div>

    <div class="max-h-screen-3/4 overflow-hidden rounded-none md:rounded-bs-10xl mt-6 md:mt-0">
      <template v-if="bannerInfo">
        <a v-if="bannerInfo.clickable" :href="bannerInfo.url" target="_blank">
          <img
            :src="bannerInfo.img"
            alt="hero"
            class="w-full h-auto rounded-none md:rounded-bs-10xl"
          >
        </a>

        <img
          v-else
          :src="bannerInfo.img"
          alt="hero"
          class="w-full h-auto rounded-none md:rounded-bs-10xl"
        >
      </template>
      
      <div v-else class="bg-gray-200 animate-pulse w-full h-48 md:h-96"></div>
      <!--@/assets/images/pages/home/hero-homepage.png-->
    </div>
  </div>
</template>

<script>
import InfoService from "@/services/InfoService";

export default {
  name: "Hero",
  data() {
    return {
      search: "",
      bannerInfo: null,
    };
  },
  created() {
    this.getBannerImage();
  },
  methods: {
    getBannerImage() {
      InfoService.getHomePageBanner()
        .then(({ data }) => {
          this.bannerInfo = data;
        });
    },
  },
};
</script>
