import { OFFLINED, ONLINED } from "@/store/enums/actions";
import { SET_OFFLINE } from "@/store/enums/mutations";

const state = {
  offlineMode: localStorage.getItem('offline')?.toLowerCase() === true.toString() || false,
};

const getters = {
  isOffline(state) {
    return state.offlineMode;
  },
};

const actions = {
  [OFFLINED]({ commit }) {
    commit(SET_OFFLINE, true);
    localStorage.setItem('offline', true.toString())
  },
  [ONLINED]({ commit }) {
    commit(SET_OFFLINE, false);
    localStorage.setItem('offline', false.toString())
  },
};

const mutations = {
  [SET_OFFLINE](state, offline) {
    state.offlineMode = offline;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
