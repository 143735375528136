import axios from "axios";

const baseURL = "https://app.grandbitex.net/webservice/";

const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getHomePageBanner() {
    return apiClient.get("banner");
  },
  getReports() {
    return apiClient.get("reports");
  },
  getPolicy() {
    return apiClient.get("policy");
  },
  getUserLevels() {
    return apiClient.get("user/level");
  },
};
