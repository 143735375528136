import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "../node_modules/@fortawesome/fontawesome-free/js/all";
import "./main.css";

import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css";

import VueLazyLoad from "vue3-lazyload";
import "./registerServiceWorker";
import "@/core/plugins/yektanet";

import { initEvents as initOfflineOnlineEvents } from "@/core/helpers/offlineHelper";
// import { registerGlobalComponents } from '@/core/helpers/globalComponentsRegistrar'

import BaseAlert from "@/components/utilities/alerts/BaseAlert";
import BaseInput from "@/components/utilities/form/BaseInput";
import BaseButton from "@/components/utilities/form/BaseButton";
import RoseSpinner from "@/components/utilities/RoseSpinner";

export const Vue = createApp(App);

Vue.use(router);
Vue.use(store);

Vue.use(VueLazyLoad);

// registerGlobalComponents();
Vue.component("BaseAlert", BaseAlert);
Vue.component("BaseInput", BaseInput);
Vue.component("BaseButton", BaseButton);

Vue.component("RoseSpinner", RoseSpinner);

Vue.mount("#app");

initOfflineOnlineEvents();
