<template>
  <div class="flex space-s-1">
    <div class="w-6 h-2 transform -skew-x-35 bg-green-400 rounded-sm"></div>
    <div class="w-6 h-2 transform -skew-x-35 bg-blue-700 rounded-sm"></div>
    <div class="w-6 h-2 transform -skew-x-35 bg-gray-300 rounded-sm"></div>
  </div>
</template>

<script>
export default {
  name: "ColoredRectangleDividers",
};
</script>
