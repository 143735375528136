<template>
  <div class="space-y-8">
    <div class="flex gap-6">
      <TwoColorDivider />

      <SectionTitle subtitle="ما را دنبال کنید"
                    title="اخبار و اطلاعیه‌ها" />
    </div>

    <RoseSpinner v-if="news.length === 0"></RoseSpinner>

    <swiper :autoplay="swiperOptions.autoplay"
            :breakpoints="swiperOptions.breakpoints"
            :loop="swiperOptions.loop"
            :modules="swiperOptions.modules"
            :pagination="swiperOptions.pagination"
            :slides-per-view="1"
            :space-between="10"
            class="custom-swiper"
            @slideChange="onSlideChange"
            @swiper="onSwiper">
      <swiper-slide v-for="(item, index) in news"
                    :key="index"
                    class="!h-56">
        <div class="flex flex-col justify-between group h-full overflow-hidden border-2 border-gray-200 rounded-lg bg-no-repeat bg-left-top
                    transition hover:border-white hover:bg-white dark:hover:bg-gray-800 dark:hover:border-gray-800 hover:shadow-lg p-6">
          <div>
            <div class="text-4xl font-black transition group-hover:text-blue-600">{{ getDay(item) }}</div>

            <div class="text-sm text-gray-400">{{ getDate(item) }}</div>
          </div>

          <div class="space-y-1.5">
            <!--          <div class="text-sm text-gray-400 font-bold transition group-hover:text-blue-600">{{ get }}</div>-->

            <h4 class="">{{getTitle(item) }}</h4>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Autoplay, Pagination } from "swiper";
import RoseSpinner from "@/components/utilities/RoseSpinner.vue";
import SectionTitle from "@/components/utilities/titles/SectionTitle";
import TwoColorDivider from "@/components/utilities/dividers/TwoColorDivider";
import NewsService from "@/services/NewsService";

export default {
  name: "News",
  components: {
    Swiper,
    SwiperSlide,
    TwoColorDivider,
    SectionTitle,
    RoseSpinner,
  },
  data() {
    return {
      news: [],
      swiperOptions: {
        breakpoints: {
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        },
        modules: [Autoplay, Pagination],
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        pagination: {
          clickable: true,
        },
        loop: true,
      },
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      NewsService.getNews()
        .then(({ data }) => {
          this.news = data;
        });
    },
    getDateParts(date) {
      const dateTimeFormat = new Intl.DateTimeFormat(
        "fa",
        { day: "2-digit", month: "long", year: "numeric" },
      );

      return dateTimeFormat.formatToParts(date * 1000);
    },
    getDatePart(date, dateType) {
      const dateParts = this.getDateParts(date);

      const part = dateParts.find(part => part.type === dateType);

      return part ? part.value : "";
    },
    getDay(newsItem) {
      return this.getDatePart(newsItem.date, "day");
    },
    getDate(newsItem) {
      const month = this.getDatePart(newsItem.date, "month");
      const year = this.getDatePart(newsItem.date, "year");

      return month + " " + year;
    },
    getTitle(newsItem) {
      const title = newsItem.text;

      return title ? title : "";
    },
    onSwiper() {
      // console.log("onSwiper");
    },
    onSlideChange() {
      // console.log("onSlideChange");
    },
  },
};
</script>
