import axios from "axios";

const baseURL = "https://mag.grandbitex.net/wp-json/wp/v2/";

const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getLatestPosts(perPage) {
    return apiClient.get("posts?_embed" + "&page=1" + "&per_page=" + perPage);
  },
};
