<template>
  <div>
    <div>
      <SectionTitle title="ویژگی‌های گرندبیتکس" subtitle="حرفه‌ای خرید و فروش کن" />

      <ColoredRectangleDividers class="mt-4 mx-1" />
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-16 mt-20">
      <Feature v-for="(feature, index) in features"
               :key="index"
               :icon="feature.icon"
               :is-new="feature.isNew"
               :subtitle="feature.subtitle"
               :title="feature.title" />
    </div>
  </div>
</template>

<script>
import Feature from "@/components/pages/home/Feature";
import ColoredRectangleDividers from "@/components/utilities/dividers/ColoredRectangleDividers";
import SectionTitle from "@/components/utilities/titles/SectionTitle";

export default {
  name: "Features",
  components: { SectionTitle, ColoredRectangleDividers, Feature },
  data() {
    return {
      features: [
        {
          icon: require("@/assets/images/icons/shield.svg"),
          subtitle: "خرید و فروش",
          title: "امنیت کامل معاملات",
        },
        {
          icon: require("@/assets/images/icons/wallet.svg"),
          subtitle: "نگهداری بلندمدت",
          title: "ذخیره در کیف شخصی شما",
          isNew: true,
        },
        {
          icon: require("@/assets/images/icons/exchange.svg"),
          subtitle: "بدون محدودیت",
          title: "پشتیبانی از تمام رمزارزها",
        },
        {
          icon: require("@/assets/images/icons/money.svg"),
          subtitle: "تضمین قیمت",
          title: "کارمزد پایین",
        },
      ],
    };
  },
};
</script>
