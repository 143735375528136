<template>
  <div v-if="loading"
       class="flex justify-center">
    <RoseSpinner></RoseSpinner>
  </div>

  <template v-else>
    <div class="grid grid-cols-1 gap-4 mt-4">
      <div v-for="(coin, index) in filteredCoinsList"
           :key="index"
           class="rounded bg-white dark:bg-gray-800 shadow text-sm">
        <div class="flex justify-between rounded-t bg-gray-50 dark:bg-gray-700 items-center p-2">
          <div class="flex items-center gap-1">
            <CoinIcon :src="coin.icon"
                      :symbol="coin.slug"
                      class="mx-auto" />
            <span>{{ coin.name }}</span>
            <span class="text-xs">({{ coin.slug }})</span>
          </div>

          <div class="flex items-center gap-1">
            <span>{{ formatNumber(coin.usdt, 6) }}</span>
            <span>تتر</span>
          </div>
        </div>

        <div class="flex flex-col py-2">
          <div class="grid grid-cols-2 p-2">
            <div class="text-center text-green-500 space-x-1 space-x-reverse">
              <span>{{ formatNumber(getBuyPrice(coin), 0, 0) }}</span>
              <span class="text-xs">تومان</span>
            </div>

            <div class="text-center text-red-500 space-x-1 space-x-reverse">
              <span>{{ formatNumber(getSellPrice(coin), 0, 0) }}</span>
              <span class="text-xs">تومان</span>
            </div>
          </div>

          <p v-if="isDisabled(coin)" class="text-red-500 text-xs text-center font-semibold mt-2">به طور موقت غیرفعال است!</p>

          <div v-else class="flex justify-center items-center text-xs space-s-1 mt-2">
            <a :href="buyLink(coin)"
               class="text-green-600 dark:text-gray-100 bg-green-100 dark:bg-green-600 rounded hover:bg-green-600 hover:text-green-100 transition-colors py-1.5 px-2 font-bold"
               rel="noopener"
               target="_blank">
              خرید
            </a>

            <a :href="sellLink(coin)"
               class="text-red-600 dark:text-red-100 bg-red-100 dark:bg-red-600 rounded hover:bg-red-600 hover:text-red-100 transition-colors py-1.5 px-2 font-bold"
               rel="noopener"
               target="_blank">
              فروش
            </a>

            <a :href="sellLink(coin)"
               class="text-blue-600 dark:text-blue-100 bg-blue-100 dark:bg-blue-600 rounded hover:bg-blue-600 hover:text-blue-100 transition-colors py-1.5 px-2 font-bold"
               rel="noopener"
               target="_blank">
              واریز
            </a>

            <a :href="sellLink(coin)"
               class="text-gray-600 dark:text-gray-100 bg-gray-100 dark:bg-gray-600 rounded hover:bg-gray-600 hover:text-gray-100 transition-colors py-1.5 px-2 font-bold"
               rel="noopener"
               target="_blank">
              برداشت
            </a>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import CoinsListMixin from "@/mixins/CoinsListMixin";
import RoseSpinner from "@/components/utilities/RoseSpinner.vue";
import useNumberFormat from "@/core/composables/useNumberFormat";

export default {
  name: "CoinsListMobile",
  mixins: [CoinsListMixin],
  components: {
    RoseSpinner,
  },
  setup() {
    const { formatNumber } = useNumberFormat();

    return {
      formatNumber,
    };
  },
};
</script>
