<template>
  <base-alert variant="danger">
    <template #default>اتصال شما به اینترنت قطع است!</template>
    <template #desc>لطفا اتصال خود را بررسی نموده و مجددا صفحه را بارگذاری نمایید.</template>
  </base-alert>
</template>

<script>
export default {
  name: "OfflineAlert",
};
</script>
