<template>
  <div :class="[variantClass]"
       class="border-s-4 rounded shadow-md px-4 py-3 space-y-3">

    <div class="font-semibold">
      <slot></slot>
    </div>

    <div class="text-sm text-opacity-80"
         v-if="hasSlot('desc')">
      <slot name="desc"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    variant: {
      type: String,
      required: true,
    },
  },
  computed: {
    variantClass() {
      return `alert-${this.variant}`;
    },
  },
  methods: {
    hasSlot(name) {
      return !!this.$slots[name];
    },
  },
};
</script>

<style scoped>
.alert-danger {
  @apply text-red-600 bg-red-100 border-red-600
}

.alert-success {
  @apply text-green-600 bg-green-100 border-green-600
}

.alert-warning {
  @apply text-yellow-600 bg-yellow-100 border-yellow-600
}
</style>