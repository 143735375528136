<template>
  <div class="flex flex-col group items-center rounded-3xl border-2 border-gray-300 dark:border-gray-600 transition duration-300 space-y-6 p-6">
    <div class="relative rounded-4xl bg-white p-6 -mt-16 shadow-xl">
      <img :src="icon"
           alt="Makhhash feature"
           class="w-12 h-auto group-hover:transform group-hover:rotate-360 transition-transform duration-500 ease-out">

      <div v-if="isNew"
           class="absolute -right-4 top-3 text-white text-2xs font-bold rounded-full bg-green-400 shadow-lg px-2 py-0.5">
        جدید
      </div>
    </div>

    <div class="text-center space-y-2">
      <p class="text-xs text-gray-500 dark:text-gray-400">{{ subtitle }}</p>

      <h6 class="text-gray-900 dark:text-gray-200 font-bold">{{ title }}</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "Feature",
  props: {
    icon: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
