import axios from "axios";

// const API_KEY = "tRlC5LpehhWQ0B91R8CfVkCJn";
// const baseURL = "https://api.sunnyweb.ir/api/arzcoins/" + API_KEY;
const baseURL = "https://app.grandbitex.net/webservice/services/";

const apiClient = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  // getCoins(count) {
  //   return apiClient.get("/" + count);
  // },
  getCoins() {
    return apiClient.get("price");
  },
  // getTetherPrices() {
  //   return apiClient.get("tether");
  // },
};
