import store from "@/store";
import { OFFLINED, ONLINED } from "@/store/enums/actions";

export function initEvents() {
  window.addEventListener('offline', () => {
    store.dispatch(OFFLINED);
  })

  window.addEventListener('online', () => {
    store.dispatch(ONLINED);
  })
}