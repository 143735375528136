<template>
  <div class="space-y-20 mb-20">
    <Hero />

    <div class="wrapper !mt-12 !md:mt-6">
      <Features />
    </div>

    <div class="wrapper">
      <Coins />
    </div>

    <Reports />

    <div class="wrapper">
      <BlogPosts />
    </div>

    <div class="wrapper">
      <News />
    </div>
  </div>
</template>

<script>
import Coins from "@/components/coins/Coins.vue";
import BlogPosts from "@/components/BlogPosts.vue";
import Hero from "@/components/pages/home/Hero";
import Features from "@/components/pages/home/Features";
import Reports from "@/components/pages/home/Reports";
import News from "@/components/pages/home/News";

export default {
  name: "Home",
  components: {
    News,
    Reports,
    Features,
    Hero,
    Coins,
    BlogPosts,
  },
};
</script>
