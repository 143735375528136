<template>
  <div class="w-full mb-12 xl:mb-0">
    <div class="block w-full">
      <div class="overflow-x-auto">
        <table class="items-center w-full">
          <thead>
            <tr class="bg-gray-200 dark:bg-gray-800 rounded-lg">
              <th class="table-head rounded-s-lg"></th>

              <th class="table-head">
                نماد
              </th>

              <th class="table-head">
                قیمت جهانی (تتر)
              </th>

              <th class="table-head">
                قیمت خرید از ما (تومان)
              </th>

              <th class="table-head">
                قیمت فروش به ما (تومان)
              </th>

              <th class="table-head rounded-e-lg"></th>
            </tr>
          </thead>

          <tbody class="px-4">
            <tr v-if="loading">
              <td class="text-center"
                  colspan="100%">
                <RoseSpinner></RoseSpinner>
              </td>
            </tr>

            <tr v-for="(coin, index) in filteredCoinsList"
                v-else
                :key="index"
                class="transition-colors bg-white hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 p-3 shadow-sm">

              <td class="p-4 align-middle border-0 whitespace-nowrap rounded-s-lg">
                <CoinIcon :src="coin.icon"
                          :symbol="coin.slug"
                          class="mx-auto" />
              </td>

              <td class="p-4 px-6 align-middle border-0 whitespace-nowrap">
                <div class="flex flex-col gap-2">
                  <span class="text-sm">{{ coin.name }}</span>
                  <span class="text-xs">({{ coin.slug }})</span>
                </div>
              </td>

              <td class="p-4 px-6 align-middle text-gray-500 border-0 whitespace-nowrap font-bold dark:text-white">
                <span>{{ formatNumber(coin.usdt, 6) }}</span>
              </td>

              <td class="p-4 px-6 align-middle text-green-500 border-0 whitespace-nowrap font-bold">
                <span>{{ formatNumber(getBuyPrice(coin), 0, 0) }}</span>
              </td>

              <td class="p-4 px-6 align-middle text-red-500 border-0 whitespace-nowrap font-bold">
                <span>{{ formatNumber(getSellPrice(coin), 0, 0) }}</span>
              </td>

              <td class="p-4 px-6 align-middle border-0 text-xs whitespace-nowrap rounded-e-lg">
                <p v-if="isDisabled(coin)" class="text-red-500 text-sm text-center font-semibold">به طور موقت غیرفعال است!</p>

                <div v-else class="flex gap-3">
                  <a :href="buyLink(coin)"
                     class="text-green-600 dark:text-gray-100 bg-green-100 dark:bg-green-600 rounded hover:bg-green-600 hover:text-green-100 transition-colors py-1.5 px-4 text-sm font-bold"
                     rel="noopener"
                     target="_blank">
                    خرید
                  </a>

                  <a :href="sellLink(coin)"
                     class="text-red-600 dark:text-red-100 bg-red-100 dark:bg-red-600 rounded hover:bg-red-600 hover:text-red-100 transition-colors py-1.5 px-4 text-sm font-bold"
                     rel="noopener"
                     target="_blank">
                    فروش
                  </a>

                  <a :href="sellLink(coin)"
                     class="text-blue-600 dark:text-blue-100 bg-blue-100 dark:bg-blue-600 rounded hover:bg-blue-600 hover:text-blue-100 transition-colors py-1.5 px-4 text-sm font-bold"
                     rel="noopener"
                     target="_blank">
                    واریز
                  </a>

                  <a :href="sellLink(coin)"
                     class="text-gray-600 dark:text-gray-100 bg-gray-100 dark:bg-gray-600 rounded hover:bg-gray-600 hover:text-gray-100 transition-colors py-1.5 px-4 text-sm font-bold"
                     rel="noopener"
                     target="_blank">
                    برداشت
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import CoinsListMixin from "@/mixins/CoinsListMixin";
import RoseSpinner from "@/components/utilities/RoseSpinner.vue";
import useNumberFormat from "@/core/composables/useNumberFormat";

export default {
  name: "CoinsList",
  mixins: [CoinsListMixin],
  components: {
    RoseSpinner,
  },
  setup() {
    const { formatNumber } = useNumberFormat();

    return {
      formatNumber,
    };
  },
};
</script>

<style scoped>
.table-head {
  @apply text-gray-600 dark:text-gray-400 align-middle text-sm whitespace-nowrap font-semibold text-right px-6 py-4
}

table {
  border-collapse: separate;
  border-spacing: 0 0.5rem;
}
</style>
