<template>
  <template v-if="isOffline">
    <div class="flex justify-center">
      <OfflineAlert />
    </div>
  </template>

  <template v-else>
    <div class="grid grid-cols-1 md:grid-cols-2 justify-between items-center gap-y-6">
      <div class="space-y-4">
        <SectionTitle title="لیست ارزهای دیجیتال"
                      subtitle="بخرید، بفروشید و نگهداری کنید" />

        <ColoredRectangleDividers />
      </div>

      <div class="relative w-full md:w-1/2 justify-self-end">
        <SearchIcon class="absolute end-2 top-3 text-blue-400 h-5 w-5" />

        <input v-model="query"
               class="w-full text-sm bg-gray-200 dark:bg-gray-800 rounded-lg outline-none border border-gray-300 shadow-sm dark:border-gray-700 focus:ring focus:ring-blue-400 dark:focus:ring-gray-700 py-3 ps-4 pe-10"
               placeholder="جستجو در بازار..."
               type="text" />
      </div>
    </div>

    <div class="mt-4">
      <CoinsListMobile v-if="isMobile()"
                       :query="query" />

      <CoinsList v-else
                 :query="query" />
    </div>

<!--    <div class="flex justify-center mt-10">-->
<!--      <router-link class="btn btn-outline-primary text-md px-6 py-3"-->
<!--                   icon="fa fa-coins"-->
<!--                   to="#">-->
<!--        مشاهده همه ارزهای دیجیتال-->
<!--      </router-link>-->
<!--    </div>-->
  </template>
</template>

<script>
import { mapGetters } from "vuex";
import { SearchIcon } from '@heroicons/vue/outline'
import MobileDetector from "@/mixins/MobileDetector";
import CoinsList from "@/components/coins/CoinsList.vue";
import CoinsListMobile from "@/components/coins/CoinsListMobile.vue";
import Pusher from "pusher-js";
import OfflineAlert from "@/components/utilities/alerts/OfflineAlert";
import SectionTitle from "@/components/utilities/titles/SectionTitle";
import ColoredRectangleDividers from "@/components/utilities/dividers/ColoredRectangleDividers";

export default {
  name: "Coins",
  mixins: [MobileDetector],
  components: {
    SearchIcon,
    ColoredRectangleDividers,
    SectionTitle,
    OfflineAlert,
    CoinsList,
    CoinsListMobile,
  },
  data() {
    return {
      query: "",
    };
  },
  created() {
    // this.subscribe();
  },
  computed: {
    ...mapGetters(["isOffline"]),
  },
  methods: {
    subscribe() {
      // console.log("ssss");
      let pusher = new Pusher("faa5b5ae248c5a1e267b", {
        cluster: "ap2",
      });
      pusher.subscribe("prices");
      pusher.bind("price_changed", () => {
        // console.log(data);
      });
    },
  },
};
</script>

<style scoped>
.all-coins-btn {
  box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.08) !important;
}
</style>
