<template>
  <div class="flex items-center gap-1">
    <div class="w-3 h-2/3 bg-gray-300 rounded-sm -skew-x-25"></div>

    <div class="w-3 h-2/3 bg-gray-400 rounded-sm -skew-x-25"></div>
  </div>
</template>

<script>
export default {
  name: "TwoColorDivider",
};
</script>
