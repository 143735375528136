<template>
  <div class="ss02 min-h-screen">
    <Header />

    <router-view class="pt-24" />

    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
